.social-links {
  padding: 0 20px;
  margin: 0 auto;

  @media (--medium) {
    padding: 0 40px;
  }

  @media (--large) {
    max-width: 500px;
  }
}

.social-links__wrapper {
  padding-top: 80px;
}

.social-links__logo {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  display: block;
  margin: 0 auto 25px;
  overflow: hidden;
}

.social-links__logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.social-links__title {
  font-size: 18px;
  line-height: 1.6;
  color: var(--col-2);
  font-weight: 400;
  margin: 0 auto 10px;

  @media (--medium) {
    margin-top: 45px;
    margin-bottom: 20px;
  }
}

.social-links__list {
  width: 100%;
}

.social-links__list:last-child {
  padding-bottom: 70px;
}

.social-links__list-item {
  list-style: none;
  text-align: center;
  width: 100%;
}

.social-links__link {
  padding: 20px 0;
  display: block;
  width: 100%;
  background: var(--main-col);
  color: var(--col-1);
  transition: background-color 0.2s ease;
  margin: 0 auto 10px;
}

.social-links__link:hover {
  background-color: var(--main-col-hover);
}
